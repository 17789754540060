








import {Component, Prop, Vue} from "vue-property-decorator";

@Component({name: 'EcpIcon'})
export default class EcpIcon extends Vue {
  @Prop({type:Boolean, default: false})
  private active!: boolean


  @Prop({type:Number, default: 24})
  private size!: boolean

  get styles(){
    return {'ecp-icon_active': this.active}
  }
}
